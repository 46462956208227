import React from 'react'
import { EqualizerProps } from './Equalizer.types'

const barStyle = 'w-0.5 bg-white animate-equalizer-bar'

const Equalizer = ({ className }: EqualizerProps) => {
  return (
    <div
      className={`flex select-none flex-row items-end justify-between ${className}`}
    >
      <div className={`${barStyle} h-3`} />
      <div
        className={`${barStyle} h-4`}
        style={{ animationDelay: '-0.5s', animationDuration: '3s' }}
      />
      <div
        className={`${barStyle} h-5`}
        style={{ animationDelay: '-1.2s', animationDuration: '2.8s' }}
      />
      <div
        className={`${barStyle} h-2`}
        style={{ animationDuration: '2.5s' }}
      />
      <div
        className={`${barStyle} h-5`}
        style={{ animationDelay: '-0.9s', animationDuration: '2.3s' }}
      />
      <div className={`${barStyle} h-3`} style={{ animationDelay: '-2.2s' }} />
    </div>
  )
}

export default Equalizer

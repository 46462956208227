import React from 'react'
import { Region, transformRegionToMap } from 'msp-integrations'
import VideoStandalone from '@/components/molecules/VideoStandalone/VideoStandalone'
import { VideoStandaloneComponentProps } from './VideoStandaloneComponent.types'

const VideoStandaloneComponent = ({
  data,
  regions,
  ...rest
}: VideoStandaloneComponentProps) => {
  const content = transformRegionToMap(regions).get('videoOverlayRegion')

  return (
    <VideoStandalone
      className={'h-full'}
      publicId={data.publicId}
      publicIdMobile={data.publicIdMobile}
      cloudName={data.cloudName}
      hideFullWidthButton={data.hideResizeButton}
      hideMuteButton={data.hideMuteButton}
      hidePlayPauseButton={data.hidePlayButton}
      hideProgressBar={data.hideProgressBar}
      disableProgressBarClick={!data.progressBarClickable}
      autoPlay={data.autoplay}
      loop={data.loop}
      muted={data.muted}
      showPuroControls={true}
      {...rest}
    >
      {content && <Region region={content} />}
    </VideoStandalone>
  )
}

export default VideoStandaloneComponent

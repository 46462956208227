import React from 'react'

const VideoProgressBar = (
  { percentage, clickable }: { percentage: number; clickable?: boolean },
  ref: React.Ref<HTMLDivElement> | undefined
) => {
  return (
    <div
      className={`flex h-2 w-full items-center md:max-w-[25vw] ${
        clickable && 'cursor-pointer'
      }`}
      ref={ref}
    >
      <div className={'h-0.5 w-full self-center bg-white bg-opacity-20'}>
        <div
          className={'h-full bg-white opacity-100'}
          style={{ width: `${percentage}%` }}
        />
      </div>
    </div>
  )
}

const VideoProgressBarForwardRef = React.forwardRef(VideoProgressBar)

export default VideoProgressBarForwardRef
